import React from 'react';
import Layout from '../components/Layout';

export default function Publications() {
    return (
        <Layout>
            <div>
            <h1 style={{color:'#3399f3', marginBottom:'30px'}}>PUBLICATIONS/PRESENTATIONS BASIC Cognitive Study</h1>
            <div class="overflow-auto" style={{marginTop:'-10px'}} className='welcomePageContent'>
                <p>Below is a list of BASIC Cognitive publications. If you are a physician or researcher and would like more information, 
                    please contact principal investigator <a href="mailto:lmorgens@med.umich.edu" target='_blank' rel='noopener noreferrer' className='callLink'>Lewis Morgenstern, MD</a>.</p>
                <h2>Publications</h2>
                <ol>
                    <li>Mehdipanah R, Briceño EM, Malvitz M, Chang W, Lewandowski-Romps L, Heeringa SG, Levine DA, Zahuranec DB, Langa KM, Gonzales XF, Garcia N, Morgenstern LB. Exploring Pathways to Caregiver Health: The Roles of Caregiver Burden, Familism, and Ethnicity. J Aging Health. 2024 Feb 22:8982643241235970. doi: 10.1177/08982643241235970. Epub ahead of print. PMID: 38388008.</li>
                    <li>Morgenstern LB, Briceño EM, Mehdipanah R, Chang W, Lewandowski-Romps L, Gonzales XF, Levine DA, Langa KM, Garcia N, Khan N, Zahuranec DB, Heeringa SG. A Community-Based Study of Dementia in Mexican American and Non-Hispanic White Individuals. J Alzheimers Dis. 2024;97(2):649-658. doi: 10.3233/JAD-230729. PMID: 38143352; PMCID: PMC10866536.</li>
                    <li>Morgenstern LB, Briceño EB, Mehdipanah R, Chang W, Lewandowski-Romps L, Gonzales XF, Levine DA, Langa KM, Garcia N, Khan N, Zahuranec DB, Heeringa SG. A community-based study of dementia in Mexican American and non-Hispanic White individuals. J Alz Dis, in press.</li>
                    <li>Khan N, Malingagio A, Briceño EM, Mehdipanah R, Lewandowski-Romps L, Heeringa SG, Garcia N, Levine DA, Langa KM, Gonzales XF, Morgenstern LB. A Community-Based Study of Cognitive Impairment Caregiving Outcomes Pre- and During the COVID-19 Pandemic. J Appl Gerontol. 2024 Jun;43(6):700-705. doi: 10.1177/07334648231215155. Epub 2023 Nov 22. PMID: 37991816; PMCID: PMC11052669.</li>
                    <li>Malvitz M, Zahuranec DB, Chang W, Heeringa SG, Briceño EM, Mehdipanah R, Gonzales XF, Levine DA, Langa KM, Garcia N, Morgenstern LB. Driving predictors in a cohort of cognitively impaired Mexican American and non-Hispanic White individuals. J Am Geriatr Soc. 2023 Jun 29. doi: 10.1111/jgs.18493. Epub ahead of print. PMCID: PMC10755064</li>
                    <li>Khan N, Briceño EM, Mehdipanah R, Lewandowski-Romps L, Heeringa SG, Garcia N, Levine DA, Langa KM, Morgenstern LB. A community-based study of reporting demographic and clinical information concordance between informants and cognitively impaired participants. Aging Clin Exp Res. 2023 Jul;35(7):1571-1576. doi: 10.1007/s40520-023-02435-6. Epub 2023 May 19. PMCID: PMC10330588.</li>
                    <li>Becker CJ, Heeringa SG, Chang W, Briceño EM, Mehdipanah R, Levine DA, Langa KM, Gonzales XF, Garcia N, Longoria R, Springer MV, Zahuranec DB, Morgenstern LB. Differential Impact of Stroke on Cognitive Impairment in Mexican Americans and Non-Hispanic White Americans. Stroke. 2022 Nov;53(11):3394-3400. doi: 10.1161/STROKEAHA.122.039533. Epub 2022 Aug 12. PMCID: PMC9613525.</li>
                    <li>Mehdipanah R, Briceño EM, Heeringa SG, Gonzales XF, Levine DA, Langa KM, Garcia N, Longoria R, Chang W, Morgenstern LB. Neighborhood SES and Cognitive Function Among Hispanic/Latinx Residents: Why Where You Live Matters. Am J Prev Med. 2022 Oct;63(4):574-581. doi: 10.1016/j.amepre.2022.04.016. Epub 2022 Jun 9. PMCID: PMC10662479.</li>
                    <li>Gonzales XF, Heeringa SG, Briceño EM, Mehdipanah R, Levine DA, Langa KM, Garcia N, Longoria R, Morgenstern LB. Mexican Americans Participate in Research More than Expected while non-Hispanic Whites Participate Less than Expected. J Health Care Poor Underserved. 2022;33(2):590-596. doi: 10.1353/hpu.2022.0049. PMCID: PMC9132253.</li>
                    <li>Khan N, Garcia N, Mehdipanah R, Briceño EM, Heeringa SG, Levine DA, Gonzales XF, Langa KM, Longoria R, Morgenstern LB. Lack of Any Caregiving for Those with Dementia. J Alzheimers Dis. 2022;86(2):531-535. doi: 10.3233/JAD-215418. PMCID: PMC8960337.</li>
                    <li>Mehdipanah R, Briceño EM, Gonzales XF, Heeringa SG, Levine DA, Langa KM, Garcia N, Longoria R, Morgenstern LB. Dementia care needs for individuals and caregivers among Mexican Americans and non-Hispanic Whites. Aging Ment Health. 2022 Aug;26(8):1630-1641. doi: 10.1080/13607863.2021.1925222. Epub 2021 Jun 7. PMCID: PMC8864934.</li>
                    <li>Briceño EM, Mehdipanah R, Gonzales XF, Heeringa SG, Levine DA, Langa KM, Zahs D, Garcia N, Longoria R, Vargas A, Morgenstern LB. Differential Relationships Between the Montreal Cognitive Assessment and Informant-Rated Cognitive Decline Among Mexican Americans and Non-Hispanic Whites. J Geriatr Psychiatry Neurol. 2022 Jul;35(4):555-564. doi: 10.1177/08919887211029383. Epub 2021 Jul 22. PMCID: PMC8782915.</li>
                    <li>Briceño EM, Mehdipanah R, Gonzales XF, Langa KM, Levine DA, Garcia NM, Longoria R, Giordani BJ, Heeringa SG, Morgenstern LB. Bilingualism, assessment language, and the Montreal Cognitive Assessment in Mexican Americans. J Am Geriatr Soc. 2021 Jul;69(7):1971-1981. doi: 10.1111/jgs.17209. Epub 2021 May 7. PMCID: PMC8273138.</li>
                    <li>Briceño EM, Mehdipanah R, Gonzales XF, Langa KM, Levine DA, Garcia NM, Longoria R, Giordani BJ, Heeringa SG, Morgenstern LB. Neuropsychological assessment of mild cognitive impairment in Latinx adults: A scoping review. Neuropsychology. 2020 Jul;34(5):493-510. doi: 10.1037/neu0000628. Epub 2020 Apr 13. PMCID: PMC8209654.</li>
                    <li>Briceño EM, Mehdipanah R, Gonzales XF, Heeringa S, Levine DA, Langa KM, Garcia N, Longoria R, Morgenstern LB. Methods and Early Recruitment of a Community-Based Study of Cognitive Impairment Among Mexican Americans and Non-Hispanic Whites: The BASIC-Cognitive Study. J Alzheimers Dis. 2020;73(1):185-196. doi: 10.3233/JAD-190761. PMCID: PMC7282317.</li>
                </ol>
                <h2>Posters/Presentations</h2>
                <ol>
                    <li>Briceno EM, Lewandowski-Romps L, Garcia N, Heeringa S, Morgenstern LB. Association between cognitive domain scores and informant-rated cognitive decline across Hispanic/Latino/a/e/x and non-Hispanic white older adults. Accepted as oral presentation to the International Neuropsychological Society 2024 Annual Meeting symposium.</li>
                    <li>Mehdipanah R, Malvitz M, Briceño EM, Chang W, Lewandowski-Romps L, Heeringa SG, Zahuranec DB, Levine DA, Langa KM, Gonzales XF, Garcia N, Morgenstern LB. Dementia caregiver burden and its toll on caregiver health; Abstract presented at 2023 Alzheimer's Association International Conference, July 16-19, 2023, Amsterdam, Netherlands/Online.</li>
                    <li>Malvitz M, Zahuranec DB, Chang W, Heeringa SG, Garcia N, Morgenstern LB. Driving predictors in a cohort of cognitively impaired Mexican Americans and non-Hispanic whites; Abstract presented at 2023 Alzheimer's Association International Conference, July 16-19, 2023, Amsterdam, Netherlands/Online.</li>
                    <li>Briceño EM, Chang W, Heeringa SG, Becker C, Garcia N, Longoria R, Morgenstern LB. Bilingualism does not modify the association between stroke and cognitive performance in Mexican American older adults, Poster presented at 2023 International Neuropsychological Society 51st Annual Meeting, Feb 1-4, 2023, San Diego, CA.</li>
                    <li>Briceño EM, Rentería MA, Chang W, Lewandowski-Romps L, Heeringa SG, Garcia N, Morgenstern LB. The association between bilingual semantic fluency and episodic memory among bilingual Mexican American older adults. Poster presented at 2022 Alzheimer's Association International Conference, July 2022, San Diego, CA.</li>
                    <li>Khan N, Lewandowski-Romps L, Heeringa SG, Briceño EM, Longoria R, Garcia N, Morgenstern LB. A community-based study of reporting demographic and clinical information concordance between informant and cognitively impaired participant. Poster presented at 2022 Alzheimer's Association International Conference, July 2022, San Diego, CA.</li>
                    <li>Becker CJ, Chang W, Heeringa SG, Briceño EM, Morgenstern LB. History of stroke doubles the risk of dementia: The Brain Attack Surveillance in Corpus Christi (BASIC)-Cognitive project; Poster presented at 2022 International Stroke Conference, Feb 9-11, 2022, New Orleans, LA/Online.</li>
                    <li>Mehdipanah R, Briceño EM, Gonzales XF, Heeringa SG, Levine DA, Langa KM, Zahs D, Garcia N, Longoria R, Morgenstern LB. Neighborhoods disadvantage and dementia: Does where you live matter? Poster presented at 2021 American Public Health Association Annual Meeting & Expo, Oct 24-27, 2021, Denver, CO/Online.</li>
                    <li>Mehdipanah R, Briceño EM, Gonzales XF, Heeringa SG, Levine DA, Langa KM, Zahs D, Garcia N, Longoria R, Morgenstern LB. A mixed-methods approach to Examining ethnic differences in needs of caregivers and individuals with CID in Nueces county, Texas. Poster presented at 2021 American Public Health Association Annual Meeting & Expo, Oct 24-27, 2021, Denver, CO/Online.</li>
                    <li>Briceño EM, Mehdipanah R, Gonzales XF, Heeringa SG, Levine DA, Langa KM, Zahs D, Garcia N, Longoria R, Morgenstern LB. Concurrent validity of the Montreal Cognitive Assessment in a Mexican American and non-Hispanic white community: The Brain Attack Surveillance in Corpus Christi (BASIC)-Cognitive Study. Accepted for presentation at the 2020 Alzheimer’s Association International Conference, July 26-30, 2020, Amsterdam, Netherlands. Withdrawn 4/27/20 due to COVID-19.</li>
                    <li>Garcia N, Briceño EM, Heeringa SG, Mehdipanah R, Levine DA, Longoria R, Zahs D, Langa KM, Morgenstern LB. Lack of any caregiving for those with dementia: The Brain Attack Surveillance in Corpus Christi (BASIC)-Cognitive project. Accepted for presentation at the 2020 Alzheimer’s Association International Conference, July 26-30, 2020, Amsterdam, Netherlands. Withdrawn 4/27/20 due to COVID-19.</li>
                    <li>Briceño EM, Mehdipanah R, Gonzales XF, Heeringa SG, Levine DA, Langa KM, Garcia N, Longoria R, Morgenstern LB. Cognitive impairment and community resource needs for older Mexican Americans: The Brain Attack Surveillance in Corpus Christi (BASIC)- Cognitive Project. Poster presented at the 2019 Alzheimer’s Association International Conference, July 14-18, 2019, Los Angeles.</li>
                    <li>Briceño EM, Mehdipanah R, Gonzales XF, Langa KM, Levine DA, Garcia N, Longoria R, Giordani B, Heeringa SG, Morgenstern LB. Culture and diagnosis of MCI in Hispanics: A literature review. Poster presented at the 2019 Hispanic Neuropsychological Society Conference, Feb 2019, New York City.</li>
                    <li>Morgenstern LB, Mehdipanah R, Briceño EM, Langa KM, Levine DA, Garcia N, Longoria R, Heeringa SG. Design of the Brain Attack Surveillance in Corpus Christi-Cognitive (BASIC-Cognitive) study. Poster presented at the 2018 European Stroke Organization Conference, May 2018, Goteborg, Sweden.</li>
                </ol>
            </div>
            </div>
        </Layout>
    );
}